import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './Login';
import Register from './Register';
import HabitList from './HabitList';
import CustomButton from './components/Button';
import Logo from './assets/logo.svg';
import { Modal, Button, Form, Input, Divider, message } from 'antd';

const App = () => {
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState('');
    const [showRegister, setShowRegister] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
            fetchUserInfo(storedUserId);
        }
    }, []);

    const fetchUserInfo = async (userId) => {
        try {
            const response = await axios.get('https://api-test.habitmaker.bruteforce.kz/users.php', {
                params: { user_id: userId }
            });
            setUsername(response.data.username);
        } catch (error) {
            console.error('Error fetching user info:', error.response ? error.response.data : error.message);
        }
    };

    const handleLogin = (userId) => {
        setUserId(userId);
        localStorage.setItem('userId', userId);
        fetchUserInfo(userId);
    };

    const handleRegister = () => {
        setShowRegister(false);
    };

    const handleLogout = () => {
        setShowLogoutModal(true);
    };

    const confirmLogout = () => {
        setUserId(null);
        setUsername('');
        localStorage.removeItem('userId');
        setShowLogoutModal(false);
    };

    const cancelLogout = () => {
        setShowLogoutModal(false);
    };

    return (
        <div>
            <div className="logo">
                <img src={Logo} />
                <div style={{
                    color: '#aaa',
                    fontSize: '12px', 
                    textAlign: 'center', 
                    marginTop: '4px',
                    marginBottom: '4px'
                }}
                >Beta</div>
            </div>

            {userId ? (
                <div className="app-body">
                    <div className="app-header">
                        <div style={{ fontSize: '14px', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '16px', paddingBottom: '16px' }}>
                            Welcome, {username}!
                            <Button 
                                onClick={handleLogout}
                                size="small"
                                danger
                                type="link"
                            >Log out</Button>
                        </div>
                    </div>
                    <HabitList userId={userId} />
                </div>
            ) : (
                <div className="login-block">
                    {showRegister ? (
                        <Register onRegister={handleRegister} />
                    ) : (
                        <Login onLogin={handleLogin} />
                    )}
                    <hr style={{ marginTop: '32px', marginBottom: '32px' }} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CustomButton 
                            text={showRegister ? 'Login' : 'Register'}
                            theme='secondary'
                            fullWidth={true}
                            size="medium"
                            onClick={() => setShowRegister(!showRegister)}
                        />
                    </div>
                </div>
            )}

            <Modal
                title="Confirm Logout"
                open={showLogoutModal}
                onOk={confirmLogout}
                onCancel={cancelLogout}
                okText="Logout"
                cancelText="Stay"
            >
                <p>Are you sure you want to log out?</p>
            </Modal>

            <div className="poweredby" style={{
                color: '#99aaaa',
                fontSize: '12px', 
                textAlign: 'center', 
                marginTop: '10px',
                marginBottom: '20px'
            }}
            >By <a href="https://almazbisenbaev.github.io">Almaz Bisenbaev</a></div>
        </div>
    );
};

export default App;