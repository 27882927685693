import './AddHabit.css';
import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Input, Divider, message } from 'antd';

const AddHabit = ({ userId, onAdd }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [goal, setGoal] = useState('');
    const [error, setError] = useState('');
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = async () => {
        try {
            console.log('Sending add habit request...');
            console.log('Name:', name);
            console.log('Description:', description);

            const response = await axios.post('https://api-test.habitmaker.bruteforce.kz/habits.php', {
                user_id: userId,
                name,
                description,
                goal,
                isdone: false // Set isdone to false by default
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Add habit response:', response.data);
            onAdd(); // Call the function to refresh the habit list
            message.success('Habit added successfully');
            setTimeout(() => {
                setVisible(false);
                setName('');
                setDescription('');
            }, 1000);
        } catch (error) {
            console.error('Error during add habit:', error.response ? error.response.data : error.message);
            setError('Failed to add habit. Please try again.');
        }
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <div className="habits-footer">
                <Button 
                    type="dashed" 
                    block={true} 
                    onClick={showModal}
                    size="large"
                >
                    Start a New Habit
                </Button>
            </div>

            <Modal
                title="Start a new habit"
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >

                <Divider />

                <Form.Item label="Habit Name" layout="vertical">
                    <Input
                        size="large"
                        placeholder="Habit Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Habit Description" layout="vertical">
                    <Input
                        size="large"
                        placeholder="Habit Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="How many days are you willing to commit?" layout="vertical">
                    <Input
                        type="number"
                        size="large"
                        value={goal}
                        onChange={(e) => setGoal(e.target.value)}
                    />
                </Form.Item>

                {error && <p style={{ color: 'red' }}>{error}</p>}

            </Modal>
        </>
    );
};

export default AddHabit;
